import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { RadioGroup } from "@headlessui/react";

import { CheckIcon } from "@heroicons/react/24/outline";

import { Alert } from "@/components/ui/Alert";
import { Button } from "@/components/ui/Button";
import {
  SendFeedbackDialog,
  SendFeedbackProps,
} from "@/features/account/components/SendFeedbackDialog";
import { usePaymentsStore } from "@/features/payments/hooks";
import { bindDialogState, useDialog } from "@/hooks/use-dialog";
import { useToasts } from "@/providers/ToastsProvider";
import {
  OrderProductInput,
  PaymentMode,
  PriceRecurringInterval,
  Product,
  ProductType,
} from "@/types";
import { cn } from "@/utils/utils";

type Plan = {
  price: { monthly: string; annually: string };
  netPrice: { monthly: string; annually: string };
  includedFeatures: string[];
};

export const SalonPlanCard = () => {
  const { t } = useTranslation();
  const { loading: loadingPayments, orderProduct, listProducts } = usePaymentsStore();
  const { showToast } = useToasts();

  const feedbackDialogState = useDialog<SendFeedbackProps>();

  const [activePeriod, setActivePeriod] = useState<"monthly" | "annually">("monthly");
  const [products, setProducts] = useState<Product[]>([]);

  const loading = loadingPayments.loadingBillingPortalSession;

  const plan: Plan = {
    price: {
      monthly: `149 zł / ${t("generic.monthAbbreviation")}`,
      annually: `990 zł / ${t("generic.year")}`,
    },
    netPrice: {
      monthly: `121,14 zł ${t("generic.net")}`,
      annually: `804,88 zł ${t("generic.net")}`,
    },
    includedFeatures: [
      t("pricing.tiers.salon.features.0"),
      t("pricing.tiers.salon.features.1"),
      t("pricing.tiers.salon.features.2"),
      t("pricing.tiers.salon.features.3"),
      t("pricing.tiers.salon.features.4"),
      t("pricing.tiers.salon.features.5"),
      t("pricing.tiers.salon.features.6"),
      t("pricing.tiers.salon.features.7"),
      t("pricing.tiers.salon.features.8"),
      t("pricing.tiers.salon.features.9"),
    ],
  };

  const handleOrder = async () => {
    const selectedPrice =
      activePeriod === "monthly"
        ? products[0]?.price
        : products[0]?.prices.find((p) => p.recurring?.interval === PriceRecurringInterval.Year);

    const orderProductInput = {
      paymentMode: PaymentMode.Subscription,
      product: {
        priceId: selectedPrice?.id,
        productId: products[0]?.price?.productId,
        quantity: 1,
        type: ProductType.Plan,
      },
    } as OrderProductInput;

    try {
      const { data } = await orderProduct(orderProductInput);

      if (data && data.orderProduct?.url) {
        window.location.href = data.orderProduct?.url;
      }
    } catch (error) {
      console.error(error);

      showToast({
        type: "error",
        title: t("generic.oops"),
        description: t("generic.somethingWentWrong"),
      });

      return;
    }
  };

  useEffect(() => {
    listProducts(ProductType.Plan).then((result) => {
      setProducts(result.data?.listProducts || []);
    });
  }, []);

  return (
    <>
      <div className="xl:flex-0 relative mx-auto rounded-xl ring-1 ring-stone-200 xl:mx-0 xl:flex xl:max-w-none">
        <div className="p-8 sm:p-10 lg:flex-auto">
          <h3 className="mb-3 flex items-center text-sm font-semibold text-stone-900">
            {t("pricing.tiers.salon.name")}
          </h3>
          <h3 className="text-3xl font-semibold tracking-tight text-stone-900">
            {t("pricing.tiers.salon.title")}
          </h3>
          <p className="mt-6 max-w-lg text-base/7 text-stone-600">
            {t("pricing.tiers.salon.description")}
          </p>

          <div className="mt-10 flex items-center gap-x-4">
            <h4 className="flex-none text-sm/6 font-semibold text-gold-600">
              {t("pricing.tiers.salon.youGet")}
            </h4>
            <div className="h-px flex-auto bg-stone-100" />
          </div>
          <ul
            role="list"
            className="mt-8 grid grid-cols-1 gap-4 text-sm/6 text-stone-600 sm:grid-cols-2 sm:gap-6">
            {plan.includedFeatures.map((feature) => (
              <li key={feature} className="flex gap-x-3">
                <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-gold-600" />
                {feature}
              </li>
            ))}
          </ul>
        </div>
        <div className="-mt-2 min-w-fit flex-1 p-2 lg:mt-0 lg:w-full">
          <div className="h-full rounded-md bg-stone-50 py-10 text-center ring-1 ring-inset ring-stone-900/5 lg:flex lg:flex-col lg:justify-start lg:py-16">
            <div className="mx-auto max-w-sm px-8">
              <div className="mb-8">
                <p className="font-semibold text-stone-600">{t("pricing.choosePaymentPeriod")}</p>

                <div
                  id="pricing"
                  aria-labelledby="pricing-title"
                  className="mx-auto max-w-6xl px-0">
                  <div className="flex justify-center py-2">
                    <div className="relative">
                      <RadioGroup
                        value={activePeriod}
                        onChange={setActivePeriod}
                        className="grid grid-cols-2">
                        {["monthly", "annually"].map((period) => (
                          <RadioGroup.Option
                            key={period}
                            value={period}
                            className={cn(
                              "cursor-pointer border border-stone-300 px-[calc(theme(spacing.3)-1px)] py-[calc(theme(spacing.2)-1px)] text-center text-sm text-stone-600 outline-2 outline-offset-2 transition-colors hover:border-stone-400",
                              period === "monthly" ? "rounded-l-md" : "-ml-px rounded-r-md",
                            )}>
                            {period === "monthly" ? t("pricing.monthly") : t("pricing.annually")}
                          </RadioGroup.Option>
                        ))}
                      </RadioGroup>
                      <div
                        aria-hidden="true"
                        className={cn(
                          "pointer-events-none absolute inset-0 z-10 grid grid-cols-2 overflow-hidden rounded-md bg-gold-600 transition-all duration-300",
                          activePeriod === "monthly"
                            ? "[clip-path:inset(0_50%_0_0)]"
                            : "[clip-path:inset(0_0_0_calc(50%-1px))]",
                        )}>
                        {["monthly", "annually"].map((period) => (
                          <div
                            key={period}
                            className={cn(
                              "py-2 text-center text-sm font-semibold text-white [&:not(:focus-visible)]:focus:outline-none",
                              period === "annually" && "-ml-px",
                            )}>
                            {period === "monthly" ? t("pricing.monthly") : t("pricing.annually")}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <p className="relative mt-6 flex items-baseline justify-center gap-x-2 text-4xl font-semibold tracking-tight text-stone-900">
                <span
                  aria-hidden={activePeriod === "annually"}
                  className={cn(
                    "transition duration-300",
                    activePeriod === "annually" &&
                      "pointer-events-none translate-x-8 select-none opacity-0",
                  )}>
                  {plan.price.monthly}
                </span>
                <span
                  aria-hidden={activePeriod === "monthly"}
                  className={cn(
                    "absolute left-8 top-0 transition duration-300",
                    activePeriod === "monthly" &&
                      "pointer-events-none -translate-x-8 select-none opacity-0",
                  )}>
                  {plan.price.annually}
                </span>
              </p>
              {plan.netPrice.monthly && activePeriod === "monthly" && (
                <p className="mt-1 text-sm lowercase text-stone-500">{plan.netPrice.monthly}</p>
              )}
              {plan.netPrice.annually && activePeriod === "annually" && (
                <p className="mx-auto mt-1 max-w-48 text-sm lowercase text-stone-500">
                  {plan.netPrice.annually}{" "}
                  <span className="font-semibold text-stone-700">
                    ({t("pricing.tiers.salon.save")})
                  </span>
                </p>
              )}
              {activePeriod === "monthly" && (
                <div className="-mb-6 mt-6 rounded-md border border-green-200 text-start">
                  <Alert title={t("pricing.promo")} type="success" textSize="xs">
                    <p>
                      {t("pricing.usePromoCode")} <span className="font-semibold">LUTY50</span>{" "}
                      {t("pricing.andGetDiscount")}{" "}
                      <span className="font-semibold">
                        99 zł / {t("generic.monthAbbreviation")}!
                      </span>
                    </p>
                  </Alert>
                </div>
              )}
              <Button
                onClick={handleOrder}
                variant="primary"
                loading={loading}
                className="mt-10 w-full rounded-md shadow-sm">
                {t("actions.subscribe")}
              </Button>
              <p className="mt-4 text-xs/5 text-stone-600">
                <span className="font-semibold">{t("pricing.dontWantPayByCard")} </span>
                <Button
                  variant="primary-inline"
                  onClick={() =>
                    feedbackDialogState.open({
                      subject: "other",
                    })
                  }>
                  {t("pricing.contactUs")}.{" "}
                </Button>
                {t("pricing.otherPaymentMethods")}
              </p>
            </div>
          </div>
        </div>
      </div>

      <SendFeedbackDialog {...bindDialogState(feedbackDialogState)} />
    </>
  );
};
